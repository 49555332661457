.app {
    display: flex;
    flex-direction: column;
}

.app_container {
    display: grid; 
    grid-template-columns: 60% 40%;
    grid-template-rows: 95% 5%;
    height: 100vh;
}

.left-side {
    grid-column: 1;
    grid-row: 1;
}

.right-side {
    grid-column: 2; 
    grid-row: 1;
    display: flex; 
    flex-direction: column;
}

.title {
    margin-left: 5%;
    font-size: 50px;
    margin-bottom: 0;
}
.lorem {
    margin-right: 5%;
    font-size: 16px;
    text-align: justify;
}
.plaque { 
    margin-left: 5%;
    width: 80%;   
}
.sketches {
    height: 40px; 
    width: 100px; 
    border: 1px solid black; 
    background-color: rgb(255, 255, 255);
    box-shadow: 3px 3px 0px rgb(0, 0, 0);
    transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;
}

.button_container {
    width: 250px; 
    display: flex;
    margin-top: 50px;
    margin-left: 25%;
    justify-content: space-between;
}

#footer_format {
    grid-row: 2;
    grid-column: 1 / span 2;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
}

* {
    font-family: "casablanca-urw", sans-serif;
    font-weight: 300;
    font-style: normal;
}

@media (max-width: 768px) {
    .app_container {
        display: flex; 
        flex-direction: column;
        grid-template-rows: 30% 50% 10%;
        height: 100vh;
    }
    .lorem {
        margin-right: 5%;
        font-size: 12px;
        text-align: justify;
        margin-bottom: 0;
    }
   
    #footer_format {
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-content: center;
        font-size: 10px;
    }
}