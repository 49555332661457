@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.test {
    min-height: 80vh;
    min-width: 80vh;
    background-color: white;
    display: flex;
    animation: fadeIn 1s ease-in-out;

}

#container {
    height: 75%;
    background-color: white;
}

#button_container {
    margin-left: 5%;
}

.button {
    margin-left: 10%;
    background-color: transparent;
    border: none;
    font-size: 16px;
}

.button:hover {
    font-weight: 800;
}
.button:active {
    font-weight: 800;
}

.background {
    position: absolute;
    z-index: -1;
    width: 60%;
    text-align: justify;
}

#button_container {
    width: 100px; 
    display: flex;
}



@media (max-width: 768px) {
    
    .test {
        min-height: 60vh;
        min-width: 60vh;
        background-color: white;
        display: flex;
        animation: fadeIn 1s ease-in-out;
    }
    #button_container {

        width: 100px;
        box-sizing: border-box; 
        color: black;
        
    }
    .button {
        margin: 10px;
        padding: 0;
        border: none;
        color: black;
    }
}